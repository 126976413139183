




const Linkedin = () => {

    return (
      <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 255.98958 255.98958"
      width="55px"
      height="55px"
      fillRule="nonzero"
    >
      <g transform="translate(70.39713,70.39713) scale(0.45,0.45)">
        <g
          fill="none"
          fillRule="nonzero"
          stroke="#ffffff"
          strokeWidth="25.6"
          strokeLinecap="butt"
          strokeLinejoin="miter"
          strokeMiterlimit="10"
          style={{ mixBlendMode: 'normal' }}
        >
          <path d="M127.99479,362.13163c-129.31021,0 -234.13684,-104.82663 -234.13684,-234.13684v0c0,-129.31021 104.82663,-234.13684 234.13684,-234.13684h0c129.31021,0 234.13684,104.82663 234.13684,234.13684v0c0,129.31021 -104.82663,234.13684 -234.13684,234.13684z"></path>
        </g>
        <g
          fill="#ffffff"
          fillRule="nonzero"
        >
          <g transform="scale(5.12,5.12)">
            <path d="M50.0625,10.4375c-1.84766,0.82031 -3.82812,1.37109 -5.91016,1.62109c2.125,-1.27344 3.75781,-3.28906 4.52344,-5.6875c-1.98437,1.17578 -4.19141,2.03125 -6.53125,2.49219c-1.875,-2 -4.54687,-3.24609 -7.50391,-3.24609c-5.67969,0 -10.28516,4.60156 -10.28516,10.28125c0,0.80469 0.09375,1.58984 0.26953,2.34375c-8.54687,-0.42969 -16.12109,-4.52344 -21.19531,-10.74609c-0.88672,1.52344 -1.39062,3.28906 -1.39062,5.17187c0,3.56641 1.8125,6.71484 4.57422,8.5625c-1.6875,-0.05469 -3.27344,-0.51953 -4.66016,-1.28906c0,0.04297 0,0.08594 0,0.12891c0,4.98438 3.54688,9.13672 8.24609,10.08594c-0.85937,0.23438 -1.76953,0.35938 -2.70703,0.35938c-0.66406,0 -1.30859,-0.0625 -1.9375,-0.1875c1.3125,4.08203 5.10938,7.0625 9.60547,7.14453c-3.51562,2.75781 -7.94922,4.39844 -12.76953,4.39844c-0.83203,0 -1.64844,-0.04687 -2.44922,-0.14453c4.54687,2.92188 9.95312,4.62109 15.76172,4.62109c18.91406,0 29.25781,-15.66797 29.25781,-29.25391c0,-0.44531 -0.01172,-0.89453 -0.02734,-1.33203c2.00781,-1.44922 3.75,-3.26172 5.12891,-5.32422z"></path>
          </g>
        </g>
      </g>
    </svg>
    );
  };
  
  export default Linkedin;